import type { SectionCustomFields } from '~/types/models/cms/cmsSectionTypes';
import { getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import type { CmsSectionDefault } from '@shopware-pwa/composables-next';

export type UseCmsSectionReturn = {
    sectionClasses: ComputedRef<Record<string, boolean>>;
};

/**
 * Composable for CMS section. Options to provide backgroundType and position
 * @param content
 */
export function useCmsSection(content: CmsSectionDefault): UseCmsSectionReturn {
    const customFields: SectionCustomFields | null = (content.customFields as SectionCustomFields) || null;

    const cmsSectionBackgroundType = customFields?.backgroundType ?? undefined;
    provide('cmsSectionBackgroundType', cmsSectionBackgroundType);
    provide('sectionPosition', content.position as number);

    const { cssClasses } = getCmsLayoutConfiguration(content);
    const sectionClasses = computed(() => ({
        ...cssClasses,
        'text-gray-500': customFields?.backgroundType === 'light',
        'text-white': customFields?.backgroundType === 'dark',
    }));

    return {
        sectionClasses,
    };
}
